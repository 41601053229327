/* General */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  background-color: #4a90e2;
  padding: 20px;
  text-align: center;
  color: white;
}

/* User Header */
.user-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details p {
  margin: 0;
}

.login-button {
  padding: 10px 20px;
  background-color: white;
  color: #4a90e2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #e0e0e0;
}

/* Chat Interface */
.chat-container {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* User Query Bubble */
.user-query {
  display: flex;
  align-items: flex-end;
}

.user-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-bubble {
  background-color: #d1e7fd;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

/* Bot Response Bubble */
.bot-response {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.bot-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.bot-bubble {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

/* Input Form */
.query-form {
  display: flex;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
}

.query-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}
.user-info {
  position: relative;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.user-dropdown p {
  margin: 5px 0;
}

.user-dropdown button {
  padding: 5px 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
